import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/ElectoralLiteracy/Classleader/1.jpg';
// import p2 from 'assests/Photos/ElectoralLiteracy/Classleader/2.jpg';
// import p3 from 'assests/Photos/ElectoralLiteracy/Classleader/3.jpg';
// import p4 from 'assests/Photos/ElectoralLiteracy/Classleader/4.jpg';
// import p5 from 'assests/Photos/ElectoralLiteracy/Classleader/5.jpg';
// import p6 from 'assests/Photos/ElectoralLiteracy/Classleader/6.jpg';
// import p7 from 'assests/Photos/ElectoralLiteracy/Classleader/7.jpg';
// import p8 from 'assests/Photos/ElectoralLiteracy/Classleader/8.jpg';
// import p9 from 'assests/Photos/ElectoralLiteracy/Classleader/9.jpg';
// import p10 from 'assests/Photos/ElectoralLiteracy/Classleader/10.jpg';
// import p11 from 'assests/Photos/ElectoralLiteracy/Classleader/11.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';
import { Sidebar } from 'views/ElectoralLiteracy2023/components';

const ClassleaderElection2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/1.webp`;
    const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/2.webp`;
    const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/3.webp`;
    const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/4.webp`;
    const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/5.webp`;
    const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/6.webp`;
    const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/7.webp`;
    const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/8.webp`;
    const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/9.webp`;
    const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/10.webp`;
    const p11 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/clubs/electoral-club/Classleader/11.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1.2,
            cols: 1,
        },
        {
          src: p7,
          source: p7,
          rows: 1.2,
          cols: 1,
      },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
      },
      
      {
          src: p10,
          source: p10,
          rows: 1,
          cols: 1,
      },
      {
          src: p11,
          source: p11,
          rows: 1,
          cols: 2,
      },
      
      {
          src: p8,
          source: p8,
          rows: 1,
          cols: 2,
      },
      {
          src: p9,
          source: p9,
          rows: 1,
          cols: 2,
      },
      {
          src: p6,
          source: p6,
          rows: 1,
          cols: 2,
      },
   
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                CLASS LEADERS ELECTION 
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 6 to 8 Date: 14 September 2023

                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                 {/* <br /> */}
                                 “Voting is not only our right—it is our power.- Loung Ung”

                            </Typography>


                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The electoral process began with the announcement of Election rules and the Code of Conduct in each class. From each class four students were chosen as candidates or "ticket" and their political symbols were released. Election campaigns took place for a two-week period between the announcement of the final list of candidates and the date of polling. During this period the candidates discussed their election manifesto and interacted with their voters. Also, the candidates and their supporters sang slogans, displayed their flags and mobilised their supporters. There was a great fervour and excitement in the air. On the polling day five booths were set up with secret ballots. Election commission executives managed the discipline, checked ID cards, gave the ballot paper, inked the left hand index finger of the voters and the voters casted their vote in ballot box prepared by each class. On Friday, 15 September 2023 the ballot boxes were opened in each class, votes were counted and the results were announced. Two candidates who secured the highest votes were declared as winners amidst great joy and applause.
                                    <br></br>
                                    The learning outcome of this activity was to ensure that the students get an experience of the procedures and the process followed during elections in India. Through this Experiential Learning activity the students understood the power of vote and how it can make a difference to their lives and society. They also learnt that voting is not just a choice but a duty of every eligible citizen of the country. Children are creators of their own destiny and also of their country's. It was truly an unforgettable day for all.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “THE BALLOT IS STRONGER THAN BULLET”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 600}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ClassleaderElection2023;